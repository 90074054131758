<script lang="ts"></script>

<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" class="size-6">
    <path
        d="M37.372 34.7804H24.3263C22.8853 34.7804 21.7172 35.9485 21.7172 37.3895V38.694C21.7172 39.4145 22.3013 39.9986 23.0217 39.9986H38.6766C39.397 39.9986 39.9811 39.4145 39.9811 38.694V37.3895C39.9812 35.9485 38.813 34.7804 37.372 34.7804Z"
        fill="white"
    ></path>
    <path
        d="M22.7663 0.764297C21.7471 -0.254922 20.096 -0.254453 19.0768 0.763828L11.466 8.37422C10.4468 9.39344 10.4468 11.0454 11.466 12.0641C12.4852 13.0834 14.136 13.0831 15.1554 12.0646L22.7663 4.45422C23.7855 3.435 23.7855 1.78305 22.7663 0.764297Z"
        fill="white"
    ></path>
    <path
        d="M37.5268 15.5357C36.5076 14.5174 34.8565 14.517 33.8374 15.5362L26.2274 23.1466C25.2081 24.1653 25.2081 25.8173 26.2274 26.8365C27.2468 27.8552 28.8978 27.8552 29.9168 26.836L37.5268 19.2257C38.546 18.2068 38.546 16.555 37.5268 15.5357Z"
        fill="white"
    ></path>
    <path d="M24.4917 6.40625L17.1121 13.7855L24.491 21.1646L31.8705 13.7847L24.4917 6.40625Z" fill="white"></path>
    <path
        d="M16.1985 16.5625L1.16105 31.5999C-0.360977 33.122 -0.360977 35.6093 1.16105 37.1313L1.16973 37.1399C2.74324 38.6955 5.21598 38.6245 6.69238 37.1313L21.7334 22.0976L16.1985 16.5625Z"
        fill="white"
    ></path>
</svg>
